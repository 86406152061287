import { z } from "zod";
import { TFunctionNonStrict } from "i18next";

export function schemaSignInUser(t: TFunctionNonStrict<"translation">) {
  return z.object({
    email: z
      .string({ required_error: t("errorMessages.emailValid") || "" })
      .min(1, {
        message: t("errorMessages.emailValid") || "",
      })
      .email(t("errorMessages.emailValid") || ""),
    password: z
      .string({ required_error: t("errorMessages.passwordValid") || "" })
      .min(2, {
        message: t("errorMessages.passwordValid") || "",
      }),
  });
}
export function schemaAddEditTeam(t: TFunctionNonStrict<"translation">) {
  return z.object({
    teamName: z
      .string({ required_error: t("errorMessages.fieldRequired") || "" })
      .min(1, {
        message: t("errorMessages.fieldRequired") || "",
      }),
    nameAbbreviation: z
      .string({ required_error: t("errorMessages.fieldRequired") || "" })
      .min(1, {
        message: t("errorMessages.passwordValid") || "",
      }),
  });
}
export function schemaSignUpUserToClaim(t: TFunctionNonStrict<"translation">) {
  return z.object({
    firstName: z
      .string({ required_error: t("errorMessages.fieldRequired") || "" })
      .min(1, {
        message: t("errorMessages.fieldRequired") || "",
      }),
    lastName: z
      .string({ required_error: t("errorMessages.fieldRequired") || "" })
      .min(1, {
        message: t("errorMessages.fieldRequired") || "",
      }),
    agreeToTerms: z
      .string({ required_error: t("errorMessages.fieldRequired") || "" })
      .refine((val) => val, {
        message: t("errorMessages.agreeToTerms") || "",
      }),

    email: z
      .string({ required_error: t("errorMessages.emailValid") || "" })
      .min(1, {
        message: t("errorMessages.emailValid") || "",
      })
      .email(t("errorMessages.emailValid") || ""),
    password: z
      .string({ required_error: t("errorMessages.passwordValid") || "" })
      .min(1, {
        message: t("errorMessages.passwordValid") || "",
      }),
  });
}
export function schemaAddOpponent(t: TFunctionNonStrict<"translation">) {
  return z.object({
    opponentName: z
      .string({
        required_error:
          t("schedulePages.matchOpponentNameValidationError") || "",
      })
      .min(1, {
        message: t("schedulePages.matchOpponentNameValidationError") || "",
      }),
    organizationId: z
      .string({
        required_error: t("schedulePages.organizationValidationError") || "",
      })
      .min(1, {
        message: t("schedulePages.organizationValidationError"),
      }),
  });
}
export function schemaEditOpponent(t: TFunctionNonStrict<"translation">) {
  return z.object({
    opponentName: z.string().min(1, {
      message: t("schedulePages.matchOpponentNameValidationError"),
    }),
    opponentAbbreviation: z.string().min(1, {
      message: t("schedulePages.matchOpponentAbbreviationValidationError"),
    }),
    organizationId: z.string().min(1, {
      message: t("schedulePages.organizationValidationError"),
    }),
  });
}
export function schemaSignUpUser(t: TFunctionNonStrict<"translation">) {
  return z.object({
    agreeToTerms: z
      .boolean({
        required_error: t("errorMessages.agreeToTerms") || "",
      })
      .refine((val) => val, {
        message: t("errorMessages.agreeToTerms") || "",
      }),
    email: z
      .string({
        required_error: t("errorMessages.emailValid") || "",
      })
      .min(1, {
        message: t("errorMessages.emailValid") || "",
      })
      .email(t("errorMessages.emailValid") || ""),
    password: z
      .string({
        required_error: t("errorMessages.passwordValid") || "",
      })
      .min(1, {
        message: t("errorMessages.passwordValid") || "",
      }),
  });
}

export function schemaUpdateUser(t: TFunctionNonStrict<"translation">) {
  return z.object({
    firstName: z
      .string({ required_error: t("errorMessages.firstNameRequired") || "" })
      .min(1, {
        message: t("errorMessages.firstNameRequired") || "",
      }),
    lastName: z
      .string({ required_error: t("errorMessages.lastNameRequired") || "" })
      .min(1, {
        message: t("errorMessages.lastNameRequired") || "",
      }),
    gender: z
      .string({ required_error: t("errorMessages.genderRequired") || "" })
      .min(2, {
        message: t("errorMessages.genderRequired") || "",
      }),
  });
}

export function schemaNewMatch(t: TFunctionNonStrict<"translation">) {
  return z.object({
    opponentId: z
      .string({ required_error: t("schedulePages.selectOpponentError") || "" })
      .min(1, {
        message: t("schedulePages.selectOpponentError"),
      }),
  });
}

export function schemaNewTraining(t: TFunctionNonStrict<"translation">) {
  return z.object({
    title: z
      .string({
        required_error: t("schedulePages.trainingTitleValidationError") || "",
      })
      .min(2, {
        message: t("schedulePages.trainingTitleValidationError"),
      }),
  });
}
export function schemaNewEvent(t: TFunctionNonStrict<"translation">) {
  return z.object({
    title: z
      .string({
        required_error: t("schedulePages.eventTitleValidationError") || "",
      })
      .min(2, {
        message: t("schedulePages.eventTitleValidationError"),
      }),
  });
}
